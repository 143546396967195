import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../helpers/fetchData";
import defaultpng from "../images/icons/default.jpg";

function Workshops(props) {
  let navigate = useNavigate();
  const [data, setData] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    getData(year);
  }, []);

  const getData = async (year) => {
    props.loderFunction(true);
    try {
      const res = await fetchData("getOrganizationBlogsV1", "Support", "POST", {
        OrganizationID: process.env.REACT_APP_ORGANIZATION_ID,
        category: 287,
        orderBy: year,
      });
      if (res?.data && res?.status !== "error") {
        let dateSplit;
        res?.data.forEach((item) => {
          dateSplit = item?.postdate.split("-");
          item["postdate"] = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
        });
        setData(res?.data);
      }
    } catch (err) {
      console.error(err);
      props.loderFunction(false);
    }
    props.loderFunction(false);
  };

  return (
    <section className="mt-5">
      {data && (
        <div className="band">
          {data.map((item, ind) => {
            return (
              <div
              className={`item-${ind}`}
              onClick={() =>
                  navigate({
                    pathname: "/event/" + item?.page_url,
                  })
                }
              >
                <a className="my-card">
                  <div
                    className="thumb"
                    style={{
                      backgroundImage: `url(${
                        item?.image != null || "" ? item?.image : defaultpng
                      })`,
                    }}
                  ></div>
                  <article>
                    <h1>{item?.blog_title}</h1>
                    <p className="m-0 mt-3">{item?.short_description}</p>
                    <span>{item?.postdate}</span>
                  </article>
                </a>
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
}

export default Workshops;
