import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchData } from "../helpers/fetchData";
import defaultpng from "../images/icons/default.jpg";

function Projects(props) {
  let navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState(false);
  const [category, setCategory] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    if (params?.slug !== "") {
      getData(year, params.slug);
    }
  }, [params.slug]);

  const getData = async (year, categoryId) => {
    props.loderFunction(true);
    try {
      const res = await fetchData("getOrganizationBlogsV1", "Support", "POST", {
        OrganizationID: process.env.REACT_APP_ORGANIZATION_ID,
        category: categoryId,
        orderBy: year,
      });
      console.log(res);
      if (res?.data && res?.status !== "error") {
        let dateSplit;
        res?.data.forEach((item) => {
          dateSplit = item?.postdate.split("-");
          item["postdate"] = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
        });
        setData(res?.data);
        setCategory(res?.category);
      }
    } catch (err) {
      console.error(err);
      props.loderFunction(false);
    }
    props.loderFunction(false);
  };

  return (
    <section className="mt-5">
      {data && (
        <div className="container">
          <h2 className="col-md-12 mb-2 text-center">
            {category.categoryName}
          </h2>
          <div className="row justify-content-center">
            <p
              className="col-lg-10 col-md-10 col-sm-12 col-12 mt-4 p-2 text-align-justify text-wrap"
              dangerouslySetInnerHTML={{ __html: category?.categoryDesc }}
            ></p>
          </div>
          <div className="band">
            {data.map((item, ind) => {
              return (
                <div
                  className={`item-${ind}`}
                  onClick={() =>
                    navigate({
                      pathname: "/event/" + item?.page_url,
                    })
                  }
                >
                  <a className="my-card">
                    <div
                      className="thumb"
                      style={{
                        backgroundImage: `url(${
                          item?.image != null || "" ? item?.image : defaultpng
                        })`,
                      }}
                    ></div>
                    <article>
                      <h1>{item?.blog_title}</h1>
                      <p className="m-0 mt-3">{item?.short_description}</p>
                      <span>{item?.postdate}</span>
                    </article>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </section>
  );
}

export default Projects;
