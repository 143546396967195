import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import memorialEvent from "../images/page/events/memorial-event.jpg";
import defaultpng from "../images/icons/default.jpg";

function Awareness(props) {

  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");


  const handleImageClick = (image) => {
    setModalImage(image);
    setShowModal(true);
  };

  let data = [];

  let memorialItem = {};

  memorialItem.page_url = "memorial";
  memorialItem.image = memorialEvent;
  memorialItem.blog_title = "Memorial lecture";
  memorialItem.short_description = "<a href = 'https://docs.google.com/forms/d/e/1FAIpQLSeis_lwzfQh81nrwubAHdqKzuVyTdu2IYjb0XZewkPfA8qkaw/viewform' target='_blank'><i class='fa fa-hand-o-right animated-icon' style='font-size:20px;color:brown'></i><b>Register here!</b></a>";
  data.push(memorialItem);

  return (
    <section className="mt-5">
        <div className="band">
          {data.map((item, ind) => {
            return (
              <div
                className={`item-${ind}`}
              >
                <a className="my-card">
                  <div
                    className="thumb"
                    style={{
                      backgroundImage: `url(${
                        item?.image != null || "" ? item?.image : defaultpng
                      })`,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageClick(item?.image || defaultpng);
                    }}
                  ></div>
                </a>
              </div>
            );
          })}
        </div>
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Body>
          <img className="w-100 mh-100 img-fluid rounded" src={modalImage} alt="Event"/>
          </Modal.Body>
        </Modal>
    </section>
  );
}

export default Awareness;
