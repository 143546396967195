import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import independenceEvent from "../images/page/events/independence-event.jpg";
import defaultpng from "../images/icons/default.jpg";


function UpcomingEvents(props) {

  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const handleImageClick = (image) => {
    setModalImage(image);
    setShowModal(true);
  };

  let data = [];

  let independence_item = {};

  independence_item.page_url = "independence";
  independence_item.image = independenceEvent;
  independence_item.blog_title = "Freedom Trail";
  independence_item.description  = 'This <img data-emoji="🇮🇳" class="iiJ4W" alt="🇮🇳" aria-label="🇮🇳" draggable="false" src="https://fonts.gstatic.com/s/e/notoemoji/15.1/1f1ee_1f1f3/512.png=s20" loading="lazy"> Independence Day weekend (18/08/2024), explore the courage of our Freedom Heroes with FoHS. Stop at <b>ETTAYAPURAM</b> to see the home of poet Bharathiyar, visit the samadi of carnatic musical legend Dikshitar and the home of literary genius Umar Pulavar. Let us go on to <b>OTTAPIDARAM</b> to see the rich life and library of Patriot VO Chidambaramnar, learn how he gave it all to our nation. Enjoy reliving the life of fiery Veerapandian Kattabomman at his fort & Jakkamal temple at <b>PANCHALANKURICHI</b> followed by his memorial at <b>KAITHAR</b>. <br><br><i>Let\'s celebrate the legacy of our freedom. </i>';
  independence_item.short_description = "<a href = 'https://docs.google.com/forms/d/e/1FAIpQLSc3fjzQTPyIL_Bc82tYiC9_9ENmyWECWDzsqbBVni61DQskVg/viewform' target='_blank'><i class='fa fa-hand-o-right animated-icon' style='font-size:20px;color:brown'></i><b>Register here!</b></a>";
  data.push(independence_item);

  return (
    <section className="mt-5">
      <div className="band">
      {data.map((item, ind) => {
      return (
      <div
        className={`item-${ind}`}
      >
        <a className="my-card">
          <div
            className="thumb"
            style={{
              backgroundImage: `url(${
                item?.image != null || "" ? item?.image : defaultpng
              })`,
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleImageClick(item?.image || defaultpng);
            }}
          ></div>
          <article>
            <p className="m-0 mt-3 content" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
            <p className="m-0 mt-3" dangerouslySetInnerHTML={{ __html: item?.short_description }}></p>
          </article>
        </a>
      </div>
            );
          })}
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Body>
        <img className="w-100 mh-100 img-fluid rounded" src={modalImage} alt="Event"/>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default UpcomingEvents;
