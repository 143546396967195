
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { fetchData } from "../helpers/fetchData";
import Logo from "../images/icons/timeline.png";
import defaultpng from "../images/icons/default.jpg";

function Pallavas(props) {
  let navigate = useNavigate();
  const [events, setEvents] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    getEvents(year);
  }, []);

  const getEvents = async (year) => {
    props.loderFunction(true);
    try {
      const res = await fetchData("getOrganizationBlogsV1", "Support", "POST", {
        OrganizationID: process.env.REACT_APP_ORGANIZATION_ID,
        category: 279,
        // year: year,
        orderBy: year,
      });
      if (res?.data && res?.status !== "error") {
        let dateSplit;
        res?.data.forEach((item) => {
          dateSplit = item?.postdate.split("-");
          item["postdate"] = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
        });
        setEvents(res?.data);
      }
    } catch (err) {
      props.loderFunction(false);
    }
    props.loderFunction(false);
  };
  return (
    <div className="container">
      {events && (
        <VerticalTimeline lineColor="#0e09008f">
          {events?.map((item, ind) => {
            return (
              <VerticalTimelineElement
                className="vertical-timeline-element--work rounded"
                contentStyle={{
                  background: "#66520acc",
                  color: "#fff",
                  cursor: "pointer",
                }}
                contentArrowStyle={{ borderRight: "7px solid #66520acc" }}
                date={item?.postdate}
                iconStyle={{
                  background: "rgb(201, 172, 92)",
                  color: "#fff",
                  cursor: "pointer",
                }}
                icon={
                  <img
                    src={Logo}
                    className="img-thumbnail rounded-circle"
                    title={`${item?.blog_title} - ${item?.postdate}`}
                    alt={item?.page_url}
                  />
                }
                iconOnClick={(item) => {
                  if (item?.target?.alt) {
                    navigate({
                      pathname: "/event/" + item?.target?.alt,
                    });
                  }
                }}
                onTimelineElementClick={(item) => {
                  if (item?.target?.alt) {
                    navigate({
                      pathname: "/event/" + item?.target?.alt,
                    });
                  }
                }}>
                <div className="row gy-4 gx-4" name={item?.page_url}>
                  <img
                    src={item?.image != null ? item?.image : defaultpng}
                    className="w-100 p-0 border border-secondary img-fluid rounded"
                    title={`${item?.blog_title} - ${item?.postdate}`}
                    alt={item?.page_url}
                  />
                  <h3 className="vertical-timeline-element-title">
                    {item?.blog_title}
                  </h3>
                  <p className="text-align-justify text-wrap">
                    {item?.short_description}
                  </p>
                </div>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      )}
    </div>
  );
}

export default Pallavas;